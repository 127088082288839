<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">
          {{ $t("role.sidebar.become_producer_label") }}
        </div>
      </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
          {{ error.general().join(" ") }}
        </CAlert>

        <form action="" @submit.prevent="onSubmit">
          <CRow class="mb-3">
            <CCol md="6">
              <div class="card-title">
                {{ $t("wizard.newrole.step2.title") }}
              </div>

              <CInput
                :label="$t('models.role.name')"
                :description="$t('models.role.name_info')"
                :value.sync="form.name"
                type="text"
                :isValid="error.isValidField('name')"
                :invalidFeedback="error.fieldError('name')"
              />

              <CInput
                :label="$t('models.family.name')"
                :description="$t('models.family.name_info')"
                :value.sync="form.family_name"
                type="text"
                :isValid="error.isValidField('family_name')"
                :invalidFeedback="error.fieldError('family_name')"
              />

              <CInput
                :label="$t('models.role.phone')"
                :description="$t('models.role.phone_info')"
                :value.sync="form.tel"
                type="text"
                :isValid="error.isValidField('tel')"
                :invalidFeedback="error.fieldError('tel')"
              />

              <CInput
                :label="$t('models.role.web')"
                :description="$t('models.role.web_info')"
                :value.sync="form.url"
                type="text"
                :isValid="error.isValidField('url')"
                :invalidFeedback="error.fieldError('url')"
              />
            </CCol>
            <CCol md="6">
              <div class="card-title">
                {{ $t("models.family_address.address") }}
              </div>

              <CInput
                v-model="form.address"
                :label="$t('models.family_address.address')"
                type="text"
                :isValid="error.isValidField('address')"
                :invalidFeedback="error.fieldError('address')"
              />

              <CInput
                v-model="form.city"
                :label="$t('models.family_address.city')"
                type="text"
                :isValid="error.isValidField('city')"
                :invalidFeedback="error.fieldError('city')"
              />

              <CSelect
                :label="$t('models.family_address.country')"
                :options="countryOptions"
                :value.sync="form.country"
                :isValid="error.isValidField('country')"
                :invalidFeedback="error.fieldError('country')"
                custom
              />

              <div class="form-group">
                <label>{{ $t("models.family_address.postal_code") }}</label>
                <v-select
                  class="bg-white"
                  label="code"
                  v-model="form.postal_code"
                  :options="postalCodeOptions"
                  @search="fetchPostalCodeOptions"
                />
                <div
                  v-if="error.hasFieldsError('postal_code')"
                  class="invalid-feedback d-block"
                >
                  {{ error.fieldError("postal_code") }}
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow class="mb-3">
            <CCol md="6">
              <div class="card-title">
                {{ $t("wizard.newrole.step3.title") }}
              </div>

              <CSelect
                :label="$t('models.family.f_type')"
                :value.sync="form.f_type"
                :options="familyTypeOptions"
                :isValid="error.isValidField('f_type')"
                :invalidFeedback="error.fieldError('f_type')"
                custom
              />

              <div class="form-group" v-show="showTaxExempt">
                <label>{{ $t("models.role.tax_exempt") }}</label>
                <UiCInputRadioGroup
                  :label="$t('models.role.tax_number')"
                  :description="$t('models.role.tax_number_info')"
                  :checked.sync="form.tax_exempt"
                  :options="taxExemptOptions"
                  inline
                />
              </div>

              <CInput
                v-show="showTaxNumber"
                :label="$t('models.role.tax_number')"
                :description="$t('models.role.tax_number_info')"
                :value.sync="form.tax_number"
                type="text"
                :isValid="error.isValidField('tax_number')"
                :invalidFeedback="error.fieldError('tax_number')"
              />

              <CInput
                :label="$t('models.family_wallet.iban')"
                :description="$t('models.family_wallet.iban_info')"
                :value.sync="form.iban"
                type="text"
                :isValid="error.isValidField('iban')"
                :invalidFeedback="error.fieldError('iban')"
              />

              <CInput
                :label="$t('models.family_wallet.bic')"
                :description="$t('models.family_wallet.bic_info')"
                :value.sync="form.bic"
                type="text"
                :isValid="error.isValidField('bic')"
                :invalidFeedback="error.fieldError('bic')"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol md="3">
              <vue-ladda
                :loading="saving"
                data-style="zoom-in"
                button-class="btn btn-primary btn-block"
                type="submit"
              >
                {{ $t("producer.products.form.btnSave") }}
              </vue-ladda>
            </CCol>
          </CRow>
        </form>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import get from "lodash/get";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../ds";
import errorResponse from "../../../helpers/error";
import {
  getFamilyTypeOptions,
  getNationsActiveOptions,
  getPostalCodeOptions,
  getYesNoOptions,
} from "../../../helpers/options";
import UiCInputRadioGroup from "../../../common/form/UiCInputRadioGroup";
import { FAMILY_TYPE } from "../../../config/global";
import { mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  name: "NewProducer",

  components: {
    vSelect,
    UiCInputRadioGroup,
  },

  data() {
    const familyTypeOptions = getFamilyTypeOptions(this);
    const familyName = get(
      this.$store,
      "state.connections.current.family.name",
      ""
    );
    const familyFType = get(
      this.$store,
      "state.connections.current.family.f_type",
      ""
    );
    return {
      saving: false,
      error: errorResponse(),
      form: {
        name: "",
        family_name: familyName,
        tel: "",
        url: "",
        f_type: familyFType,

        tax_exempt: null,
        tax_number: "",
        iban: "",
        bic: "",

        address_id: null,
        address: "",
        city: "",
        postal_code: null,
        country: "",
      },
      countryOptions: [],
      postalCodeOptions: [],
      familyTypeOptions: familyTypeOptions,
    };
  },

  computed: {
    showTaxExempt() {
      return [FAMILY_TYPE.SOCIETY, FAMILY_TYPE.COOP].includes(this.form.f_type);
    },

    showTaxNumber() {
      return (
        [FAMILY_TYPE.SOCIETY, FAMILY_TYPE.COOP].includes(this.form.f_type) &&
        this.form.tax_exempt === true
      );
    },
    ...mapGetters("connections", ["familyId"]),

    taxExemptOptions() {
      return getYesNoOptions(this.$i18n);
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      getNationsActiveOptions().then((options) => {
        this.countryOptions = [
          {
            value: null,
            label: this.$t("producer.products.form.preview.variantPlaceholder"),
          },
          ...options,
        ];
      });
      this.prepolateAddress();
    },

    prepolateAddress() {
      const familyId = this.$store.state.connections.current.family.id;
      const dm = GetDataManagerNew("auth_faddress", [familyId]);
      dm.executeQuery(new Query().where("default_bill", "equal", true)).then(
        (res) => {
          if (res.result.length >= 1) {
            const address = res.result[0];
            this.form.address_id = address.id;
            this.form.address = get(address, "address", "");
            this.form.city = get(address, "city", "");
            this.form.postal_code = get(address, "postal_code", null);
            this.form.country = get(address, "country", "");
          }
        }
      );
    },

    prepateRequestParams(formData) {
      const fields = [
        "name",
        "family_name",
        "tel",
        "url",
        "f_type",
        "tax_exempt",
        "tax_number",
        "iban",
        "bic",
        "address_id",
        "address",
        "city",
        "postal_code",
        "country",
      ];

      return {
        ...pick(formData, fields),
        r_type: 2,
        postal_code: get(formData.postal_code, "id", ""),
        family_id: this.familyId,
      };
    },

    getCountryOptions() {
      getNationsActiveOptions().then((options) => {
        this.countryOptions = [
          {
            value: null,
            label: this.$t("producer.products.form.preview.variantPlaceholder"),
          },
          ...options,
        ];
      });
    },

    getPostalCodeOptions: debounce((search, loading, countryId, vm) => {
      getPostalCodeOptions({ countryId, search }).then((options) => {
        loading(false);
        vm.postalCodeOptions = [...options];
      });
    }, 350),

    fetchPostalCodeOptions(search, loading) {
      const countryId = this.form.country;
      if (countryId && search.length) {
        loading(true);
        this.getPostalCodeOptions(search, loading, countryId, this);
      }
    },

    onSubmit() {
      const dm = GetDataManagerNew("auth_role");

      this.error.reset();
      const requestParams = {
        ...this.prepateRequestParams(this.form),
      };
      dm.insert(requestParams)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t("user.collaborate.newProducer.toast.success"),
            color: "success",
            autohide: true,
          });
          const roleId = get(response, "id", ""); // fixme
          this.redirect(roleId);
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    redirect(role_id) {
      this.$store.dispatch("connections/fetchAll").then(() => {
        this.$store
          .dispatch("connections/fetchByRoleId", role_id)
          .then(({ connection, role }) => {
            this.$store.commit("setRole", role);
            this.$store.commit("connections/setCurrent", connection);
            this.$router.push({
              name: "RoleDashboard",
              params: { id: role_id },
            });
          });
      });
    },
  },
};
</script>
